import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export const Media = ({
    featureChannel,
    brightcoveProps,
    videoPlayerEleId,
    config = {}
}) => {
    const playerWidgetRef = useRef(null);
    const { accountId, policyKey, playerId } = brightcoveProps;

    const brightcoveId = featureChannel.customAttributes.find(
        (attr) => attr.name === 'brightcove_videoid_web'
    )?.value;

    const mediaImageUrl = featureChannel.customAttributes.find(
        (attr) => attr.name === 'thumbnailURL'
    )?.value;

    const mediaType =
        featureChannel?.info?.channelTypes?.[0]?.mediaType || 'VIDEO';

    const customAdTagUrl =
        mediaType === 'AUDIO' ? config?.audioAdTagUrl : config?.videoAdTagUrl;

    const disableAds = featureChannel.customAttributes.find(
        (attr) => attr.name === 'disableAds'
    )?.value;

    useEffect(() => {
        if (playerWidgetRef.current) {
            const additionalProperties = {};
            if (customAdTagUrl) {
                additionalProperties.adTagUrl = customAdTagUrl;
            }

            if (disableAds) {
                additionalProperties.disableAds = disableAds;
            }

            if (mediaType === 'AUDIO') {
                new PULSE.app.common.AudioPlayer(
                    playerWidgetRef.current,
                    [],
                    additionalProperties
                );
            } else {
                new PULSE.app.common.Video(
                    playerWidgetRef.current,
                    [],
                    additionalProperties
                );
            }
        }
    }, [
        featureChannel,
        videoPlayerEleId,
        mediaType,
        customAdTagUrl,
        disableAds
    ]);

    return (
        <div
            className="inline-player inline-player--modal js-video-container is-active"
            data-widget="video-player"
            ref={playerWidgetRef}
        >
            <div className="inline-player__player-container">
                <video-js
                    id={videoPlayerEleId}
                    data-video-id={brightcoveId}
                    data-account={accountId}
                    data-policy-key={policyKey}
                    data-player={playerId}
                    class="inline-player__video video-js vjs-16-9 js-video-player"
                    controls
                    poster={mediaImageUrl}
                    autoplay={featureChannel.autoplay}
                ></video-js>
            </div>
        </div>
    );
};

Media.propTypes = {
    featureChannel: PropTypes.object.isRequired,
    brightcoveProps: PropTypes.object.isRequired,
    videoPlayerEleId: PropTypes.string.isRequired,
    config: PropTypes.object
};
