import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import classNames from 'classnames';

import { Sponsor } from './Sponsor';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { MediaTag } from 'common/react/components/MediaTag';
import {
    getTranslation,
    lookupTranslation
} from 'common/react/utils/translations';
import { ImageElement } from 'common/react/components/ImageElement';
import { Media } from './Media';
import { getTimeString } from './util/getTimeString';
import { CountdownRenderer } from './CountdownRenderer';

export const TradeRadio = ({
    event,
    config,
    brightcoveProps,
    widgetClass = ''
}) => {
    let styleObj = {
        '--trade-accent': config.accentColour,
        '--trade-accent-text': config.accentTextColour,
        '--trade-accent-hover': config.accentHoverColour,
        '--trade-bg': config.backgroundColour
    };

    const videoPlayerEleId = `trade-radio_${brightcoveProps.anchorName}`;

    const { UPCOMING, LIVE } = PULSE.app.common.broadcast.status;

    const noCTAVisible =
        event.status === LIVE &&
        !(
            event.liveChannels?.AUDIO?.length &&
            event.liveChannels?.VIDEO?.length
        );

    // Active Channel stores what's currently loaded in the media player
    const [activeChannel, setActiveChannel] = useState(event.featureChannel);
    // Next channel queues up what we want to load into the media player
    const [nextChannel, setNextChannel] = useState(null);

    // First try and grab an image from the activeChannel, if that's null, swap to the nextChannel
    const mediaImageUrl =
        activeChannel?.customAttributes.find(
            (attr) => attr.name === 'thumbnailURL'
        )?.value ||
        nextChannel?.customAttributes.find(
            (attr) => attr.name === 'thumbnailURL'
        )?.value;

    const mediaType =
        activeChannel?.info?.channelTypes?.[0]?.mediaType || 'VIDEO';

    /**
     * Callback function for when we want to start playing media from a channel
     *
     * @param {object} channelToPlay - The channel we want to play
     */
    const playMedia = (channelToPlay) => {
        const player = videojs.getPlayer(videoPlayerEleId);
        const currentMediaId = player?.mediainfo?.id;
        const idToPlay = channelToPlay.customAttributes.find(
            (attr) => attr.name === 'brightcove_videoid_web'
        )?.value;

        // If we've already loaded it, start playing
        if (currentMediaId === idToPlay) {
            player.play();
            // Otherwise queue it up to load
        } else {
            setNextChannel(channelToPlay);
        }
    };

    /**
     * Watch for when we have a nextChannel set to start swapping it out. We
     * have to do this to make sure we get 1 render to dispose of the video
     * player in brightcove, then remove it from the dom, then re-render it with
     * the new id's to re-init it.
     */
    useEffect(() => {
        // Next channel queued up but still have active channel
        if (nextChannel && activeChannel) {
            try {
                videojs.getPlayer(videoPlayerEleId).dispose();
            } catch (error) {
                console.error(
                    '[TradeRadio] There was an error disposing of the player',
                    error
                );
            }
            setActiveChannel(null);
            // Now the active channel is gone, swap in the nextChannel to active
        } else if (nextChannel && !activeChannel) {
            setActiveChannel({
                ...nextChannel,
                autoplay: true
            });
            setNextChannel(null);
        }
    }, [activeChannel, nextChannel, videoPlayerEleId]);

    return (
        <div className={widgetClass}>
            <article className="trade-radio" style={styleObj}>
                {noCTAVisible ? (
                    <button
                        className="trade-radio__card-button"
                        onClick={() => playMedia(activeChannel)}
                        aria-label={
                            mediaType === 'AUDIO'
                                ? getTranslation(
                                      'label.tradeRadio.listenButton'
                                  )
                                : getTranslation('label.tradeRadio.watchButton')
                        }
                    ></button>
                ) : null}

                {config?.promo?.promoItem?.onDemandUrl ? (
                    <Sponsor promo={config.promo} />
                ) : null}

                <div className="trade-radio__meta">
                    {event.status === LIVE ? (
                        <MediaTag
                            iconUrl={`/resources/${window.RESOURCES_VERSION}/i/elements/live-pulse-animated.svg`}
                            cssClass={
                                'trade-radio__live-indicator media-tag--live-highlight'
                            }
                            label={getTranslation('label.Live')}
                        />
                    ) : null}

                    {event.status === LIVE ? (
                        <h2 className="trade-radio__title">{event.name}</h2>
                    ) : (
                        <h2 className="trade-radio__title">
                            {lookupTranslation(
                                'label.tradeRadio.upcomingTitle',
                                {
                                    time: getTimeString(activeChannel)
                                }
                            )}
                        </h2>
                    )}

                    {config.subtitle ? (
                        <p className="trade-radio__desc">{config.subtitle}</p>
                    ) : null}
                </div>

                {config.linkTo && config.linkToLabel ? (
                    <div className="trade-radio__link-wrapper">
                        <a className="trade-radio__link" href={config.linkTo}>
                            {config.linkToLabel}{' '}
                            <SvgIcon className="icon" icon="arrow-right" />
                        </a>
                    </div>
                ) : null}

                <div
                    className={classNames('trade-radio__media', {
                        'trade-radio__media--full-height': noCTAVisible
                    })}
                >
                    {event.status === LIVE && activeChannel ? (
                        <Media
                            featureChannel={activeChannel}
                            brightcoveProps={brightcoveProps}
                            videoPlayerEleId={videoPlayerEleId}
                            config={config}
                        />
                    ) : (
                        <ImageElement
                            url={mediaImageUrl}
                            objectFit={true}
                            width={16}
                            height={9}
                        />
                    )}
                </div>

                {event.status === UPCOMING ? (
                    <div className="trade-radio__cta-wrapper">
                        <span className="trade-radio__join-cta">
                            {getTranslation('label.tradeRadio.joinCtaPrefix')}{' '}
                            <strong>
                                <Countdown
                                    date={activeChannel.startDateTime}
                                    renderer={CountdownRenderer}
                                />
                            </strong>
                        </span>
                    </div>
                ) : !noCTAVisible ? (
                    <div className="trade-radio__cta-wrapper">
                        <button
                            className="trade-radio__cta-button button-new button-new--medium"
                            onClick={() => {
                                playMedia(event.liveChannels.VIDEO[0]);
                            }}
                        >
                            <SvgIcon
                                className="icon"
                                icon="video-playback-new"
                            />{' '}
                            {getTranslation('label.tradeRadio.watchButton')}
                        </button>
                        <button
                            className="trade-radio__cta-button button-new button-new--medium"
                            onClick={() => {
                                playMedia(event.liveChannels.AUDIO[0]);
                            }}
                        >
                            <SvgIcon className="icon" icon="listen" />{' '}
                            {getTranslation('label.tradeRadio.listenButton')}
                        </button>
                    </div>
                ) : null}
            </article>
        </div>
    );
};

TradeRadio.propTypes = {
    event: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    brightcoveProps: PropTypes.object.isRequired,
    widgetClass: PropTypes.string
};
